import React from "react";
import LightboxStore from "./lightbox/store";

import "../css/styles.scss";

const Layout = ({ className, children }) => (
  <LightboxStore>
    <div className={`page ${className || ''}`}>
      {children}
    </div>
  </LightboxStore>
)

export default Layout
