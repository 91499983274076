import React, { useState, useEffect } from "react";

export const LightboxContext = React.createContext([]);

const LightboxStore = ({ children }) => {

  const query = process.browser ? window.location.search : null;
  const [lightbox, setLightbox] = useState([]);

  // NOTE: On initial load...
  useEffect(() => {

    if (process.browser && window.location.hash) {
      const base64String = window.location.hash.substr(1)
      const decodedString = atob(base64String)
      const parsedArray = JSON.parse(decodedString)

      setLightbox(
        parsedArray.map(item => {
          return {
            id: item[0],
            projectId: item[1]
          };
        })
      )
    } else if (localStorage) {
      const existing = localStorage.getItem('lightbox');
      if (existing) {
        setLightbox(JSON.parse(existing))
      }
    }

  }, [])

  // NOTE: When lightbox updates...
  useEffect(() => {
    if (!query) {
      localStorage.setItem('lightbox', JSON.stringify(lightbox))
    }
  }, [lightbox])

  return (
    <LightboxContext.Provider value={[lightbox, setLightbox]}>
      {children}
    </LightboxContext.Provider>
  )
}

export default LightboxStore;
